// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bN_k4";
export var caseStudyBackground__bgColor = "bN_kV";
export var caseStudyBackground__lineColor = "bN_kW";
export var caseStudyHead__imageAbsolute = "bN_k9";
export var caseStudyHead__imageWrapper = "bN_kT";
export var caseStudyProjectsSection = "bN_k5";
export var caseStudyQuote__bgRing = "bN_k1";
export var caseStudyTech = "bN_k3";
export var caseStudyVideo = "bN_k7";
export var caseStudyVideo__ring = "bN_k8";
export var caseStudy__bgDark = "bN_kS";
export var caseStudy__bgLight = "bN_kR";